<template>
  <div class="doctor_match_result_warp">
    <!-- 标题 -->
    <div class="match_result_title">医生数据匹配结果</div>

    <!-- 匹配信息 -->
    <div class="box match_result_info">
      <div class="floor_title">匹配信息</div>
      <div class="infomations">
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="infomation_task_id">
              <p>任务编号:</p>
              <p>{{ resultInfo.task_id }}</p>
            </div>
            <div class="infomation_create_time">
              <p>创建时间:</p>
              <p>{{ resultInfo.create_time }}</p>
            </div>
          </el-col>

          <el-col :span="8">
            <div class="infomation_remarks">
              <p>备注:</p>
              <p>{{ resultInfo.remark }}</p>
            </div>
            <div class="infomation_end_time">
              <p>完成时间:</p>
              <p>{{ resultInfo.end_time }}</p>
            </div>
          </el-col>

          <el-col :span="8">
            <div class="infomation_status">
              <p>状态:</p>
              <p>
                <i
                  v-if="resultInfo.status === '排队中'"
                  class="status_flag"
                  :style="{ color: '#FA9600', background: '#FA9600' }"
                />
                <i
                  v-else-if="resultInfo.status === '执行中'"
                  class="status_flag"
                  :style="{ color: '#1664FF', background: '#1664FF' }"
                />
                <i
                  v-else-if="resultInfo.status === '已完成'"
                  class="status_flag"
                  :style="{ color: '#04AF3E', background: '#04AF3E' }"
                />
                <i
                  v-else
                  class="status_flag"
                  :style="{ color: '#E63F3F', background: '#E63F3F' }"
                />
                {{ resultInfo.status }}
              </p>
            </div>

            <div class="infomation_create_user">
              <p>创建用户:</p>
              <p>{{ resultInfo.create_user }}</p>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <!-- 匹配结果 -->
    <div class="box match_result_content">
      <div class="match_result_content_top">
        <div class="content_top_title">匹配结果</div>
        <!-- 暂时关闭上传下载功能 -->
        <div class="content_top_btn">
          <el-button type="primary" v-auto-unfocus @click="downloadMatchResult">
            下载匹配结果
          </el-button>
          <el-button
            type="primary"
            v-auto-unfocus
            :class="{ 'match-result-exportBtn': showExportBtn, 'match-result-eb': !showExportBtn }"
            :disabled="!showExportBtn"
            @click="handleExportMatch"
          >
            导出匹配数据
          </el-button>
        </div>
      </div>

      <eyao-table
        v-show="!requestData"
        ref="table"
        class="match_result_table"
        :table-data="tableData"
        :total-num="totalNum"
        @selection-change="handleSelectionChange"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
        <!-- 复选框列 -->
        <el-table-column type="selection" :reserve-selection="true"></el-table-column>

        <el-table-column
          v-for="(column, index) in configList"
          :key="`${$route.name}-${index}`"
          :label="column.title"
          :prop="column.property"
          :width="column.width"
        >
          <template slot-scope="scope">{{ scope.row[column.property] }}</template>
        </el-table-column>

        <!-- 操作列  -->
        <el-table-column width="150" label="操作" fixed="right">
          <div
            slot-scope="scope"
            class="opt-column"
            @click="handleViewData(scope.$index, scope.row)"
          >
            查看匹配数据
          </div>
        </el-table-column>
        <template v-slot:empty>
          <div class="search-no-data" v-if="totalNum === 0 && !requestData">
            <img
              src="https://baike-med-1256891581.file.myqcloud.com/drugs_admin/20111202/6fd06465-d653-4f86-963e-cb5196e49caf.png"
              alt="未查询到相关数据"
            />
            未查询到相关数据
          </div>
        </template>
      </eyao-table>

      <!-- <div v-if="totalNum === 0 && !requestData" class="search-no-data">
        <img
          src="https://baike-med-1256891581.file.myqcloud.com/drugs_admin/20111202/6fd06465-d653-4f86-963e-cb5196e49caf.png"
          alt="未查询到相关数据"
        />
        未查询到相关数据
      </div> -->

      <template v-if="requestData">
        <div class="listLoading">
          <img src="@/assets/loading.gif" alt="loading" />
        </div>
      </template>
    </div>

    <el-drawer
      title="匹配数据"
      :visible.sync="matchDetailDrawer"
      direction="rtl"
      :size="960"
      class="matchDetail-drawer-warp"
    >
      <eyao-table
        class="matchDetailDrawer_table"
        :table-data="drawer_tableData"
        :total-num="drawer_totalNum"
        @size-change="handleDrawerSizeChange"
        @current-change="handleDrawerCurrentChange"
      >
        <el-table-column
          v-for="column in drawerConfigList"
          :key="`${$route.name}-${column.property}`"
          :label="column.title"
          :prop="column.property"
          :width="column.width"
        >
          <template slot-scope="scope">{{ scope.row[column.property] }}</template>
        </el-table-column>
        <template v-slot:empty>
          <div class="search-no-data">
            <img
              src="https://baike-med-1256891581.file.myqcloud.com/drugs_admin/20111202/6fd06465-d653-4f86-963e-cb5196e49caf.png"
              alt="未查询到相关数据"
            />
            未查询到相关数据
          </div>
        </template>
      </eyao-table>

      <!-- <div v-if="drawer_totalNum === 0" class="search-no-data">
        <img
          src="https://baike-med-1256891581.file.myqcloud.com/drugs_admin/20111202/6fd06465-d653-4f86-963e-cb5196e49caf.png"
          alt="未查询到相关数据"
        />
        未查询到相关数据
      </div> -->
    </el-drawer>
  </div>
</template>

<script>
// import store from '@/store';
import config from './config/list';
import configLabel from './config/label';
import drawerConfigList from './config/match_detail';
import eyaoTable from '@/components/table';
import * as apis from '@/api/data';
import papaparse from 'papaparse';
import { GetObjURL } from '@/api/upload';

export default {
  name: 'DoctorMatchResult',
  components: {
    eyaoTable,
  },
  data() {
    return {
      // 表格数据
      tableData: [],
      totalNum: 0,
      selectedData: [],
      // 配置文件
      configList: [],
      configLabel: [],
      resultInfo: {
        task_id: '',
        create_time: '',
        create_user: '',
        end_time: '',
        remark: '',
        status: '',
        file_name: '',
        file_url: '',
      },
      // 数据请求
      requestData: true,
      currentDataPage: 1,
      pageDataSize: 20,
      propertyName: ['pro_title', 'professional_title', 'gender'],
      showExportBtn: false,
      // 匹配数据结果详情drawer
      matchDetailDrawer: false,
      drawer_tableData: [],
      drawerConfigList: [],
      drawer_totalNum: 0,
      drawerPageSize: 20,
      drawerCurrentPage: 1,
    };
  },
  computed: {
    // 角色权限判定
    role() {
      const newRole = JSON.parse(localStorage.getItem('role'));
      // 页面刷新时
      if (newRole) {
        this.$store.commit('user/updateRole', newRole);
      } else {
        return this.$store.state.user.role;
      }

      return newRole;
    },
  },
  watch: {
    $route(to) {
      if (to.name === 'doctor-match-result') {
        this.init();
      }
    },
    role(newval) {
      if (newval === 3) {
        this.$message.error('无权限进入');
        this.$router.push('/dashboard/home');
      }
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.tableData = [];
      this.drawer_tableData = [];
      this.requestData = true;
      this.configList = config;
      this.configLabel = configLabel;
      this.drawerConfigList = drawerConfigList;
      this.resultInfo = this.$route.query;
      this.GetMatchResultList();
    },

    // 勾选数据
    handleSelectionChange(val) {
      console.log(val);
      this.selectedData = val;
      if (val.length > 0) {
        this.showExportBtn = true;
      } else {
        this.showExportBtn = false;
      }
    },

    // 获取医生数据匹配结果列表
    GetMatchResultList() {
      this.requestData = true;
      const params = {
        index: this.currentDataPage,
        page_size: this.pageDataSize,
        task_id: this.resultInfo.task_id,
      };
      apis.GetDoctorDataMatchTaskResult(params).then(res => {
        if (res && res.infos) {
          const dataList = res.infos;
          this.totalNum = res.total;

          // 处理表格数据
          this.dealTableList(dataList);
          this.dealDataLabel(dataList);
          this.tableData = dataList;
          console.log(this.tableData, 'tableData');
          setTimeout(() => {
            this.requestData = false;
          }, 500);
        }
      });
    },

    // 处理表格数据，config值
    dealTableList(list) {
      let adpetList = [];
      list.forEach(ele => {
        ele.resultId = ele.ids.join(',');
        ele.data = JSON.parse(ele.data);
        Object.keys(ele.data).forEach(e => {
          ele[`${e}`] = ele.data[e];
          adpetList.push({
            title: e,
            width: 200,
            property: e,
          });
        });
      });
      adpetList = this.dealCommonData(adpetList);
      if (adpetList) {
        this.configList = [...this.configList, ...adpetList];
      }
      console.log(this.configList);
      return list;
    },

    // 处理列表中下拉菜单的label值
    dealDataLabel(list) {
      const reg = /^[0-9a-zA-Z]/;
      list.forEach(ele => {
        Object.keys(ele).forEach(key => {
          if (this.propertyName.includes(key)) {
            if (reg.test(ele[key])) {
              ele[key] = this.getCodeLabel(key, ele[key]);
            }
          }
        });
      });
    },

    getCodeLabel(key, value) {
      const valObj = this.configLabel.filter(item => item.property === key);
      let valArr = [];
      if (JSON.stringify(valObj) !== '{}') {
        valArr = valObj[0].value;
      }
      let res = '';
      if (valArr.length > 0) {
        const arr = valArr.filter(item => item.code == value);
        if (arr.length > 0) {
          res = arr[0].label;
        }
      }
      return res;
    },

    // 防止出现相同数据
    dealCommonData(arr = []) {
      if (arr.length === 0) return;
      const obj = {};
      return arr.reduce((item, next) => {
        obj[next.property] ? '' : (obj[next.property] = true && item.push(next));
        return item;
      }, []);
    },

    // pagination操作
    handleCurrentChange(val) {
      console.log(val);
      this.currentDataPage = val;
      this.GetMatchResultList();
    },
    handleSizeChange(val) {
      console.log(val);
      this.pageDataSize = val;
      this.GetMatchResultList();
    },
    handleDrawerSizeChange(val) {
      console.log(val);
      this.drawerPageSize = val;
      // this.GetTaskList();
    },
    handleDrawerCurrentChange(val) {
      console.log(val);
      this.drawerCurrentPage = val;
      // this.GetTaskList();
    },

    // 下载匹配结果
    downloadMatchResult() {
      const { file_url, file_name } = this.resultInfo;
      console.log(file_url, file_name);
      GetObjURL({ url: file_url })
        .then(res => {
          const uploadUrl = res.data.url;
          papaparse.parse(uploadUrl, {
            download: true,
            complete(results, file) {
              console.log('Parsing complete:', results, file);
              if (results?.data) {
                const csv = papaparse.unparse(results.data);
                // console.log('downLoad', csv);
                const content = new Blob([`\ufeff${csv}`], { type: 'text/plain;charset=utf-8' });
                const urlObject = window.URL || window.webkitURL || window;
                const url = urlObject.createObjectURL(content);
                const el = document.createElement('a');
                el.href = url;
                el.download = file_name; // eslint-disable-line
                el.click();
                urlObject.revokeObjectURL(url);
              }
            },
          });
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    },

    // 导出数据匹配
    handleExportMatch() {
      const condition = {
        main_data: 't_hcp',
        data: '医生',
        keyword: '',
        index: this.currentDataPage,
        page_size: this.pageDataSize,
        search_fields: this.dataExportFields(),
        SearchOptions: [],
      };
      const exportIds = [];
      this.selectedData.forEach(item => {
        exportIds.push(...item.ids);
      });
      // console.log(ids);
      const params = {
        condition,
        // offset: 1, // 偏移量
        // limit: 0,
        ids: exportIds, // 勾选数据的id
      };

      apis.CreateDataExportTask(params).then(res => {
        console.log('导出的数据', res);
        if (res && res.task_id) {
          this.$message.success('导出任务已生成，请到导入导出任务查看进度');
          // 重置勾选
          this.selectedData = [];
          this.$refs.table.clearSelection();
        }
      });
    },

    // 数据导出字段
    dataExportFields() {
      // 系统ID、姓名、姓名拼音、性别、所属机构名称、HCP类型、HCP状态、挂牌科室、标准科室、专业职称、执业许可证编码、颁发机构、医师级别、执业类别、执业地点、执业范围、是否多点执业
      const field = [
        'id',
        'name',
        'pinyin',
        'gender',
        'hco_name',
        'hcp_type',
        'hcp_status',
        'listed_dept_name',
        'standard_dept_name1',
        'standard_dept_name2',
        'standard_dept_name3',
        'professional_title',
        'nhc_license_number',
        'nhc_issuing_authority',
        'nhc_professional_level',
        'nhc_professional_type',
        'nhc_location',
        'nhc_scope',
        'nhc_multi_sites',
      ];
      return field;
    },

    // 查看匹配数据
    handleViewData(index, row) {
      console.log(index, row);
      this.matchDetailDrawer = true;
      const params = {
        main_data: 't_hcp',
        data: '医生',
        keyword: '',
        index: this.drawerCurrentPage,
        page_size: this.drawerPageSize,
        search_fields: [
          'name',
          'gender',
          'hco_name',
          'listed_dept_name',
          'standard_dept_name',
          'standard_dept1',
          'standard_dept2',
          'standard_dept3',
          'standard_dept_name1',
          'standard_dept_name2',
          'standard_dept_name3',
          'professional_title',
          'nhc_professional_type',
          'nhc_location',
          'nhc_scope',
        ],
        SearchOptions: [
          {
            field_name: 'id',
            type: 2,
            key: row.ids.join(','),
          },
        ],
      };

      apis.SearchMainDataByType(params).then(res => {
        if (res && res.data) {
          const dataList = res.data.map(item => JSON.parse(item));
          this.drawer_totalNum = res.total_num;

          // 处理标准科室
          this.dealStandardData(dataList);

          this.dealDataLabel(dataList);
          this.drawer_tableData = dataList;
          console.log(this.drawer_tableData, 'drawer_tableData');
        }
      });
    },

    dealStandardData(dataList) {
      const arr = [];
      dataList.forEach(ele => {
        if (ele.standard_dept_name.indexOf(',') > -1) {
          ele.standard_dept_name = ele.standard_dept_name.replace(/,/g, '/');
        }
        const standardName1 = ele.standard_dept_name1 ? ele.standard_dept_name1 : '';
        const standardName2 = ele.standard_dept_name2 ? ele.standard_dept_name2 : '';
        const standardName3 = ele.standard_dept_name3 ? ele.standard_dept_name3 : '';
        arr.push({
          id: ele.id,
          standard_dept1: ele.standard_dept1,
          standard_dept2: ele.standard_dept2,
          standard_dept3: ele.standard_dept3,
          standard_dept_name1: standardName1,
          standard_dept_name2: standardName2,
          standard_dept_name3: standardName3,
        });
        const standardName = `${standardName1}/${standardName2}/${standardName3}`;
        ele.standard_dept_name = this.delEndStr(standardName);
      });
    },

    // 递归删除'/'
    delEndStr(str) {
      if (str.endsWith('/')) {
        return this.delEndStr(str.substr(0, str.length - 1));
      }
      return str;
    },
  },
};
</script>

<style lang="scss" scoped>
@import './result.scss';
</style>
