export default [
  {
    title: '性别',
    property: 'gender',
    value: [
      { code: '1', label: '女' },
      { code: '2', label: '男' },
      { code: '0', label: '未知' },
    ],
  },
  {
    title: '专业职称',
    property: 'professional_title',
    value: [
      { label: '副主任检验师', code: 'T_VCCLE' },
      { label: '美容师', code: 'O_C' },
      { label: '主管药师', code: 'P_PIC' },
      { label: '副主任医师', code: 'D_VCD' },
      { label: '副主任护师', code: 'N_VCN' },
      { label: '主任技师', code: 'T_CT' },
      { label: '主任药师', code: 'P_CP' },
      { label: '副主任技师', code: 'T_VCT' },
      { label: '技士', code: 'T_AT' },
      { label: '药士', code: 'P_AP' },
      { label: '主任护师', code: 'N_CN' },
      { label: '主任检验师', code: 'T_CCLE' },
      { label: '检验师', code: 'T_CLE' },
      { label: '主治医师', code: 'D_ATD' },
      { label: '药师', code: 'P_P' },
      { label: '营养师', code: 'O_DIET' },
      { label: '医士', code: 'D_AD' },
      { label: '统计师', code: 'O_STAT' },
      { label: '其他', code: 'Other' },
      { label: '未知', code: 'Unknown' },
      { label: '副主任药师', code: 'P_VCP' },
      { label: '康复治疗师', code: 'O_TP' },
      { label: '住院医师', code: 'D_RD' },
      { label: '技师', code: 'T_T' },
      { label: '护士', code: 'N_AN' },
      { label: '主管护师', code: 'N_NIC' },
      { label: '主任医师', code: 'D_CD' },
      { label: '主管技师', code: 'T_TIC' },
      { label: '护师', code: 'N_N' },
    ],
  },
];
