export default [
  {
    title: '姓名',
    width: 100,
    property: 'name',
  },
  {
    title: '性别',
    width: 100,
    property: 'gender',
  },
  {
    title: '所属机构名称',
    width: 300,
    property: 'hco_name',
  },
  {
    title: '挂牌科室',
    width: 200,
    property: 'listed_dept_name',
  },
  {
    title: '标准科室',
    width: 200,
    property: 'standard_dept_name',
  },
  {
    title: '专业职称',
    width: 200,
    property: 'professional_title',
  },
  {
    title: '执业类别',
    width: 100,
    property: 'nhc_professional_type',
  },
  {
    title: '执业地点',
    width: 150,
    property: 'nhc_location',
  },
  {
    title: '执业范围',
    width: 100,
    property: 'nhc_scope',
  },
];
