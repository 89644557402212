export default [
  {
    title: '查询机构名称',
    width: 200,
    property: 'org_name_keyword',
  },
  {
    title: '匹配机构名称',
    'min-width': 300,
    property: 'org_name_matched',
  },
  {
    title: '曾用名',
    'min-width': 300,
    property: 'former_name',
  },
  {
    title: '别名',
    'min-width': 300,
    property: 'alias_name',
  },

  {
    title: '机构匹配排序',
    width: 200,
    property: 'matching_degree',
  },
  {
    title: '匹配专业职称',
    width: 200,
    property: 'pro_title',
  },
];
